import React from "react";
import { Container, Typography, Grid2, Box } from "@mui/material";

const PrepareWedding = () => {
  return (
    <Box pl={3} pr={3} pt={10}>
      <Typography
        variant="h2"
        align="center"
        gutterBottom
        sx={{ fontFamily: "'Dancing Script'" }}
      >
        Preparate para la boda
      </Typography>
      <Container maxWidth="lg" sx={{ padding: 4 }}>
        <Grid2 container spacing={2}></Grid2>
      </Container>
    </Box>
  );
};

export default PrepareWedding;

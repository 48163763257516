import React from "react";
import { Box, Typography, Container } from "@mui/material";
import Itinerary from "../itinerary/itinerary";
import ImageSlider from "./imageSlider";
import "./ourStory.css";

const OurStory = () => {
  return (
    <>
      <Box id="itinerary" sx={{ py: 4 }}>
        <Itinerary />
      </Box>
      <Box id="our-story" sx={{ py: 4, backgroundColor: "#fcf7ea" }}>
        <Container>
          <Typography
            variant="h4"
            component="h1"
            align="center"
            gutterBottom
            sx={{ fontFamily: "'Dancing Script'" }}
          >
            Nuestra historia
          </Typography>

          {/* Image slider */}
          <ImageSlider />

        </Container>
      </Box>
    </>
  );
};

export default OurStory;

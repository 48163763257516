import React from 'react';
import { Box, Typography, Grid2, useMediaQuery } from '@mui/material';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useTheme } from '@mui/material/styles';
import { hotelInfo } from './hotelInfo';
const AboutHotel = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const mapDisable = true;
  console.log(isMobile);

  const mapContainerStyle = {
    width: '100%',
    height: '400px',
  };
  // Lat and long map location
  const center = {
    lat: 20.64855700932974,
    lng: -105.23976282704587,
  };
  const hotelImgWidth = `${isMobile ? (window.innerWidth - 90) : 450}px`;
  const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <Box sx={{ mt: 5, pl: 3, pr: 3, pt: 5 }}>

      {/* Heading Section */}
      <Box mb={3}>
        {/* Title and description */}
        <Typography variant="h2" align='center' gutterBottom sx={{ fontFamily: "'Dancing Script'" }}>
          Krystal Grand Nuevo Vallarta
        </Typography>
        <Grid2 container spacing={2}>
          <Typography variant='p' sx={{ fontFamily: '"Roboto", "Helvetica", "Arial", sans- serif' }} >
            {hotelInfo.overview}
          </Typography>
        </Grid2>
      </Box>

      {/* Mapa section */}
      <Box>
        <Typography variant="h3" align='center' gutterBottom sx={{ fontFamily: "'Dancing Script'" }}>
          Mapa
        </Typography>
        {!mapDisable ?
          (<LoadScript googleMapsApiKey={mapsApiKey}>
            <GoogleMap mapContainerStyle={mapContainerStyle} center={center} zoom={15}>
              <Marker position={center} />
            </GoogleMap>
          </LoadScript>) : (
            <Box
              sx={{
                width: isMobile ? '90vw' : '97vw',
                height: isMobile ? '25em' : '50em',
                backgroundColor: '#ccc'
              }}>
              <Typography sx={{
                position: 'relative', top: '45%',
                left: '0%',
                color: '#969696',
                textAlign: 'center',
                fontSize: '2rem'
              }} >
                Map disabled temporary - this is a placeholder
              </Typography>
            </Box>
          )
        }

      </Box>

      {/* Hotel Information */}
      <Box pt={5} pb={5} sx={{ width: '100%' }}>
        <Grid2 container rowSpacing={10} columnSpacing={{ xs: 1, sm: 10, md: 10 }}>
          {/* Installations info */}
          {hotelInfo.sections.map((section) => (
            <Box key={section.title} width={'45vw'}>
              <Grid2 size={6}>
                {/* Heading title */}
                <Typography variant="h2"
                  align='left'
                  gutterBottom
                  sx={{ fontFamily: "'Dancing Script'", width: '5.15em' }}>
                  {section.title}
                </Typography>
              </Grid2>
              {/* Description */}
              <Typography variant="p"
                align='left'
                gutterBottom
                sx={{ fontFamily: "'Dancing Script'", fontSize: '1.5em' }}>
                {section.desc}
              </Typography>
              {/* Picture */}
              <Grid2 size={6}>
                <img src={section.src} width={hotelImgWidth} />
              </Grid2>
              {/* List and sublist */}
              <Grid2 size={6}>
                <ul style={{ width: '18em' }}>
                  {section.listDesc && section.listDesc.map((item, index) => (
                    <li key={index}>
                      {Array.isArray(item) ? (
                        <>
                          <strong>{item[0]}</strong>
                          {Array.isArray(item[1]) && (
                            <ul style={{ marginLeft: '10px', width: '15em' }}>
                              {item[1].map((subItem, subIndex) => (
                                <li key={subIndex}>
                                  <Typography variant='p' sx={{ fontSize: '1.25em' }}>{subItem}</Typography>
                                </li>
                              ))}
                            </ul>
                          )}
                        </>
                      ) : (
                        <Typography variant='p' sx={{ fontSize: '1.3em' }}>{item}</Typography>
                      )}
                    </li>
                  ))}
                </ul>

              </Grid2>
            </Box>
          ))}
        </Grid2>
      </Box>
    </Box >
  );
};

export default AboutHotel;

import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

const Calendar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const eventTitle = "Boda de Jonathan & Samantha";
  const startDate = "20241130";
  const endDate = "20241130";
  const description = "Acompañanos este dia tan importante";
  const weddingLocation =
    "Blvd. Costero #800, Flamingos, Sur, 63732 Nuevo Vallarta, Nay.";
  const icsFile = "/path-to-your/wedding-jonathanysamantha.ics";

  const googleCalendarURL = `https://calendar.google.com/calendar/r/eventedit?text=${encodeURIComponent(
    eventTitle
  )}&dates=${startDate}/${endDate}&details=${encodeURIComponent(
    description
  )}&location=${encodeURIComponent(weddingLocation)}`;
  const outlookCalendarURL = `https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&startdt=${startDate}&enddt=${endDate}&subject=${encodeURIComponent(
    eventTitle
  )}&body=${encodeURIComponent(description)}&location=${encodeURIComponent(
    weddingLocation
  )}`;
  const yahooCalendarURL = `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${encodeURIComponent(
    eventTitle
  )}&st=${startDate}&et=${endDate}&desc=${encodeURIComponent(
    description
  )}&in_loc=${encodeURIComponent(weddingLocation)}`;

  const toggleModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(!isModalOpen);
  };

  const handleClickOutside = () => {
    if (isModalOpen) {
      setIsModalOpen(false);
    }
  };

  return (
    <Box onClick={handleClickOutside}>
      <Button variant="contained" color="primary" onClick={toggleModal} sx={{ backgroundColor: 'transparent', border: '2px solid', fontFamily: "'Dancing Script'" }}>
        Agregar a calendario
      </Button>
      <Modal open={isModalOpen} onClose={toggleModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Opciones de calendario
          </Typography>
          <Link
            href={googleCalendarURL}
            target="_blank"
            rel="noopener"
            sx={{ display: "block", mb: 1 }}
          >
            Agregar a Google Calendar
          </Link>
          <Link
            href={outlookCalendarURL}
            target="_blank"
            rel="noopener"
            sx={{ display: "block", mb: 1 }}
          >
            Agregar a Outlook Calendar
          </Link>
          <Link
            href={icsFile}
            target="_blank"
            rel="noopener"
            sx={{ display: "block", mb: 1 }}
          >
            Agregar a Apple Calendar
          </Link>
          <Link
            href={yahooCalendarURL}
            target="_blank"
            rel="noopener"
            sx={{ display: "block", mb: 1 }}
          >
            Agregar a Yahoo Calendar
          </Link>
        </Box>
      </Modal>
    </Box>
  );
};

export default Calendar;

export const events = [
  {
    title: "Itinerario",
    subtitle: 'Ceremonia',
    date: "30 de Noviembre 2024",
    time: "5:30pm",
    location: "Hotel Krystal Grand Nuevo Vallarta Playa",
    image: '/assets/pictures/itinerary/ceremony.jpg',
    attire:
      "Attire: Caftans y vestimenta casual de verano. ¡Prepárate para disfrutar del aire fresco del océano!",
  },
  {
    title: "Itinerario",
    subtitle: 'Coctel',
    date: "30 de Noviembre 2024",
    time: "6:30pm",
    location: "Hotel Krystal Grand Nuevo Vallarta Terraza",
    image: '/assets/pictures/itinerary/cocktail.jpg',
    attire: "Attire: Formal, con opción de corbata negra.",
  },
  {
    title: "Itinerario",
    subtitle: 'Recepcion',
    date: "30 de Noviembre 2024",
    time: "7:30pm",
    location: "Hotel Krystal Grand Nuevo Vallarta Terraza",
    image: '/assets/pictures/itinerary/reception.jpg',
    attire: "Attire: Formal, con opción de corbata negra.",
  },
  {
    title: "Itinerario",
    subtitle: 'Cena',
    date: "30 de Noviembre 2024",
    time: "8:00pm",
    location: "Hotel Krystal Grand Nuevo Vallarta Terraza",
    image: '/assets/pictures/itinerary/dinner.jpeg',
    attire: "Attire: Casual, pero elegante.",
  },
  {
    title: "Itinerario",
    subtitle: 'Fiesta',
    date: "30 de Noviembre 2024",
    time: "9:00pm",
    location: "Hotel Krystal Grand Nuevo Vallarta Terraza",
    image: '/assets/pictures/itinerary/wedding_party.jpg',
    attire: "Attire: Casual de fiesta.",
  },
];

import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import "./App.css"; // Global styles
import { Route, Routes, Link, useNavigate } from "react-router-dom";
import { AboutHotel, PrepareWedding } from "./components/pages";
import Home from "./components/pages/home";
function App() {
  const navigate = useNavigate();
  const pages = [
    { id: 1, title: "Inicio", path: "/" },
    { id: 2, title: "Acerca del hotel", path: "/about_hotel" },
    { id: 3, title: "Preparate para la boda", path: "/prepare_wedding" },
  ];
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    navigate(page.path);
  };
  return (
    <Box>
      <AppBar position="fixed" sx={{ backgroundColor: "#2a2a2a" }}>
        <Container>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              J & S
            </Typography>

            {/* Mobile Menu */}
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="menu"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  ".MuiPaper-root": {
                    width: "100%",
                    maxWidth: "100%",
                    left: "0 !important",
                    top: "0 !important",
                  },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.id}
                    onClick={() => {
                      handleCloseNavMenu(page);
                    }}
                  >
                    <Typography
                      component={Link}
                      to={page.path}
                      sx={{
                        textAlign: "center",
                        color: "inherit",
                        textDecoration: "none",
                      }}
                    >
                      {page.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/* Desktop Menu */}
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              J & S
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page.id}
                  component={Link}
                  to={page.path}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page.title}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <div id="content">
        <Box sx={{ p: 0 }}>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/prepare_wedding" element={<PrepareWedding />} />
            <Route path="/about_hotel" element={<AboutHotel />} />
          </Routes>
        </Box>
      </div>
    </Box>
  );
}
export default App;
